export const getRoomTypes = async () => {
    const roomTypesEndpoint = "https://nestingale.com/v1/api/roomTypes";
    
    const response = await fetch(roomTypesEndpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch room types');
    }
  
    const roomTypes = await response.json();
    return roomTypes;
  };
  