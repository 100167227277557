'use client';
import React, { useEffect, useRef, useState } from "react";
import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { createScript, deleteGoogleCredentials, getGoogleCredentialsFromLS, initGsi, isTokenExpired, renderGoogleButton } from "../../load_and_get_google_access_token";
import ClientComponent from "../builder/client_component";
import loginBg_1 from "../../assets/images/loginbg_1.png";
import loginBg_2 from "../../assets/images/loginbg_2.png";
import loginBg_3 from "../../assets/images/loginbg_3.png";
import googleLogo from "../../assets/images/google-logo.png";
import DesignsSlideshowComponent, { DesignerSlideShowData, Slide } from "../designs_slideshow";
import styles from "./login.module.scss";
import '@aws-amplify/ui-react/styles.css';

import { createFBScript, deleteFBKCredentialsFromLS, getFBKCredentialsFromLS, signInWithFacebook } from "@/utils/load_and_get_facebook_access_token";
import { useRouter } from "next/router";
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import { getLoggedInEmailAddress, gtmClickTracking, gtmPageOnloadTracking } from "@/utils";
import { getCurrentBrand, getCustIdFromLS, getUserInfo, removeCustIdFromLS, setCurrentBrand } from "@/utils/useUserInfo";
import { Box, Button, CircularProgress } from "@mui/material";
import { get } from "http";
import nestingaleLogo from "../../assets/images/logo.svg";
import AppleButton from "../popups/AppleButton";
import { appleResponseCallback } from "@/utils/load_and_get_apple_access_token";
import Link from "next/link";


interface LoginComponentProps {
    layerMode?: boolean;
    brand: string;
    loginCallback?: Function;
    isStyleQquiz: boolean;
    hideLayerTitle: boolean;
    setHideLayerTitle?: Function;
    handleDialogClose?: Function;
}

const cogLocalStorageKey = "cogls";

export const deleteDirectCognitoCredentialsFromLS = () => {
    localStorage.removeItem(cogLocalStorageKey);
}

export const setDirectCognitoCredentialsIntoLS = (tokenValue: any) => {
    localStorage.setItem(cogLocalStorageKey, JSON.stringify(tokenValue));
}

export const getDirectCognitoCredentialsFromLS = (): any => {
    try {
        const value = localStorage.getItem(cogLocalStorageKey);
        if (value) {
            return JSON.parse(value);
        }
        return ""
    } catch (e) {
        return ""
    }
}

const formFields = {
    signUp: {
        username: {
            order: 1,
            label: "Email", // Custom label for display
            placeholder: "Enter your Email", // Custom placeholder
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        },
        password: {
            order: 5
        },
        confirm_password: {
            order: 6
        }
    },
}


export async function redirectToNextPage() {

    try {
        //Navigating to next url param using URLSearchParams instead of const { next } = router.query;
        // as router is stale and having old url param values instead of current page
        let cId = getCustIdFromLS()
        try {
            if (!cId) {
                const userInfo = await getUserInfo("", true);
            }
        } catch (e) {
            console.log("Failed to save Google email Address", e)
        }

        let email = getLoggedInEmailAddress();
        cId = getCustIdFromLS()
        if (email && cId) {
            const urlParams = new URLSearchParams(window.location.search);
            const next = urlParams.get('next');
            console.log('----------next-------------', next);
            if (next) {
                return window.location.href = next.replace(/[<>*]/g, '');
            } else if (window.location.pathname.indexOf("/login/designer") > -1) {
                return window.location.href = `${getCurrentBrand()}/successconfirmation`;
            } else if ((window.location.pathname.indexOf("/login/customer") > -1
                || (window.location.pathname.indexOf("/login/dc") > -1))) {
                return window.location.href = `${getCurrentBrand()}/styleprofile`;
            } else {
                let previousPageUrl = document.referrer;
                if (previousPageUrl && previousPageUrl.indexOf("login") == -1) {
                    return window.location.href = previousPageUrl;
                } else {
                    return window.location.href = `${getCurrentBrand()}/rooms`;
                }
            }
        }
    } catch (e) { }

}

const LoginComponent: React.FC<LoginComponentProps> = ({ layerMode = false, brand, loginCallback, isStyleQquiz, hideLayerTitle, setHideLayerTitle, handleDialogClose }) => {
    setCurrentBrand(brand)
    const router = useRouter();
    const googleSignInButtonRef = useRef<HTMLDivElement>(null);
    const [redirecting, setRedirecting] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const showAppleButton = router.query.apple as string === "true";
    const logoImages = [
        { "img": loginBg_1.src, "caption": "Discover your Design Destiny" },
        { "img": loginBg_2.src, "caption": "Get Inspiration for each room of your dream home" },
        { "img": loginBg_3.src, "caption": "Brag about your style profile to your friends" }
    ]

    console.log("LoginComponent...");
    const [isLoaded, setLoaded] = useState(false);
    
    if (typeof window !== 'undefined') {
        //@ts-ignore
        if (!window.google || !window.google.accounts) {
            createScript(setLoaded)
        }
        //@ts-ignore
        if (!window.FB) {
            createFBScript();
        }
    }

    useEffect(() => {
        if (isLoaded) {
            initGsi(router, loginCallback as Function);
        }
    }, [isLoaded])


    useEffect(() => {
        //@ts-ignore
        if (window.google) {
            if (googleSignInButtonRef && googleSignInButtonRef.current &&
                googleSignInButtonRef.current.childNodes.length == 0) {
                renderGoogleButton()
            }
        }
    }, [])

    useEffect(() => {
        if (layerMode) {
            if (router.pathname.indexOf("/stylequiz") > -1) {
                // gtmPageOnloadTracking({
                //     event: 'stylequiz_login_started'
                // });
            } else {
                gtmPageOnloadTracking({
                    event: 'LoginLayer'
                });
            }
        }
    }, []);


    useEffect(() => {
        //@ts-ignore 
        if (window.nestingale && window.nestingale.triggeredRedirection) {
            setRedirecting(true);
        }
    })
    const prepareDesignerSlideShowData = (images: any): DesignerSlideShowData => {
        const slides: Slide[] = images && images.map((image: any, index: any) => {
            return { id: "design" + index, image: image.img, caption: image.caption } as Slide;
        })
        const carouselSlides = [...slides];
        return { designs: carouselSlides, dispatcher: {}, height: '100vh !important' }
    }

    const appleLoginCallback = (response: any) => {
        console.log("Apple button click callback : " + JSON.stringify(response));
        appleResponseCallback(response, loginCallback);
    }

    {/*<Authenticator hideSignUp={true} socialProviders={['facebook', 'google']}>
        </Authenticator>*/}



    if (isStyleQquiz && hideLayerTitle) {
        return (
            <Box component="div" className={styles.style_profile_signup}>
                <h3>Sign in to save your style profile, and start designing your dream home</h3>
                <Button sx={{
                    backgroundColor: 'primary.main',
                    color: '#fff',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: 'primary.main' 
                    },
                    margin: '0 auto',
                    display: 'flex',
                    width: '200px'
                }} onClick={() => {
                    if (setHideLayerTitle) {
                        setHideLayerTitle(false);
                    }
                    gtmClickTracking({ 
                        event: 'signin_from_stylequiz_preheat', 
                        action_taken: "sign_in",
                        id: "sign_in_preheat"
                    });
                }}>Sign in</Button>
                <h4>Not now? <Link href={"/shop"} onClick={()=>{
                    gtmClickTracking({ 
                        event: 'signin_from_stylequiz_preheat', 
                        action_taken: "not_now",
                        id: "not_now_preheat" 
                    });
                }}>Browse inspirations!</Link></h4>
            </Box>
        );
    }

    return (
        <>
            {!layerMode &&
                <>
                    <a href="/"><img src={nestingaleLogo.src} alt="Nestingale Logo" className={styles.logo} /></a>
                    <div className={styles["slideshow-container"]}><DesignsSlideshowComponent  {...prepareDesignerSlideShowData(logoImages)} /></div>
                </>
            }
            {
                //@ts-ignore 
                redirecting
                    ? <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        width: '50%'
                    }}>
                        <h2>Please wait...</h2>
                        <div><CircularProgress size="20" /></div>
                    </div>
                    : <div className={styles[layerMode ? "login-layer-container" : "login-left-container"]}>

                        {!layerMode && <h1 className={styles.heading}>Please sign-in or register</h1>}

                        <Authenticator formFields={formFields} signUpAttributes={["address", "gender"]}>
                            {({ signOut, user }: any) => {
                                if(user) {
                                    setIsLoggedIn(true);
                                    handleDialogClose?.();
                                }
                                //@ts-ignore
                                if (!(window.nestingale && window.nestingale.triggeredRedirection)) {
                                    // removeCustIdFromLS();
                                    // deleteGoogleCredentials();
                                    // deleteFBKCredentialsFromLS();
                                    (async () => {
                                        let currentCredentials = await Auth.currentCredentials();
                                        if (user?.signInUserSession?.idToken?.payload?.iss?.indexOf("cognito-idp") > -1) {
                                            setDirectCognitoCredentialsIntoLS({ ...currentCredentials, email: user?.attributes?.email, lastName: user?.attributes?.family_name, name: user?.attributes?.preferred_username || "User" })
                                        }
                                        if (layerMode && loginCallback) {
                                            loginCallback({ type: "cognito", status: "LOGIN_SUCCESS" });
                                        } else {
                                            setRedirecting(true);
                                            redirectToNextPage();
                                        }
                                    })()
                                }
                                return (
                                    <main>
                                        {/* <h1>Hello {user?.attributes?.preferred_username || user?.username}</h1> */}
                                        {/* <button onClick={signOut}>Sign out</button> */}
                                    </main>
                                )
                            }}
                        </Authenticator>
                        {
                            !isLoggedIn && <div className={styles["login_left-section"]}>
                                <div className={styles["google-button-wrapper"]}>
                                    <div className={styles["google-button-section"]}>
                                        <div className={styles["real-google-button"]} ref={googleSignInButtonRef} id="googleSignInButton"></div>
                                        <div className={styles["custom-google-button"]} >
                                            <img src={googleLogo.src} alt="Google logo" /> <span>Continue with Google</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div onClick={()=>signInWithFacebook(router)} className={styles.facebook_login}><FacebookIcon sx={{fontSize: '24px'}} /> <span>Sign in with Facebook</span></div> */}
                                <div className={styles["apple_login"]}><AppleIcon sx={{ fontSize: '24px' }} /> <span> <AppleButton appleLoginCallback={appleLoginCallback} /></span></div>
                            </div>
                        }
                        
                    </div>}
        </>
    )
}

export default ClientComponent(LoginComponent);