import { GetServerSidePropsContext, GetStaticPropsContext,  PreviewData } from "next";
import { getVendors } from "@/store/commonSlice";
import { configureAmplify } from "@/pages/_app";
import { Vendor } from "@/store/commonSlice";
import { ParsedUrlQuery } from "querystring";

export async function getStaticBasics(context: GetStaticPropsContext | GetServerSidePropsContext<ParsedUrlQuery, PreviewData>) {
  
    let params = context.params || (context as GetServerSidePropsContext<ParsedUrlQuery, PreviewData>).query;

    let vendor = params?.vendor as string;
    
    let slug: string[]  = params?.slug as string[]; // for non vendor pages, slug[0] will be taken as vendor if present
    let nocache: boolean = false;
    //nocache = slug?.splice(slug.indexOf('nocache'), 1).length > 0 || slug?.splice(slug.indexOf('noCache'), 1).length > 0;
    
    const env = process.env.NEXT_PUBLIC_APP_ENV === 'qa' ? 'qa' : 'prod';
    const hostName = env === 'qa' ? 'qa.nestingale.com' : 'nestingale.com';
    console.log("env: ", env, 'hostName: ', hostName, 'vendor: ', vendor, "params: ", params);
    configureAmplify({
        domain: hostName, protocol: 'https'
    });
    let pageCacheInSec = nocache ? 1 : (env === 'qa' ? 300 : 1800);

    const vendorsApiData = await getVendorsWithCache(env, pageCacheInSec);
    let vendors: Vendor[] = vendorsApiData?.vendors;
    let vendorName: string = '';
    let vendorDisplayName: string = '';
    let vendorHomePage: string = '/rooms';
    vendors.forEach((vendorObj: any) => {
      if (!vendor) {
        // if vendor is not present in the url, then check if the first path entry from slug is vendor
        if (slug && slug.length > 0 && slug[0] === vendorObj.vendorName) {
          vendor = slug[0];
          vendorName = vendorObj.vendorName;
          vendorDisplayName = vendorObj.vendorDisplayName || vendorObj.vendorName;
          vendorHomePage = vendorObj.landingPage || `${vendorName}/rooms`;
        } 
      } else {
        if (vendorObj.vendorName === vendor) {
          vendorName = vendorObj.vendorName;
          vendorDisplayName = vendorObj.vendorDisplayName || vendorObj.vendorName;
          vendorHomePage = vendorObj.landingPage || `${vendorName}/rooms`;
        }
      } 
    }); 
    if (!vendorName) {
      vendor = 'nestingale';
      vendorName = 'nestingale';
      vendorDisplayName = 'Nestingale';
    }
    return { vendor, vendorName, vendorDisplayName, vendorHomePage, env, vendorsApiData, hostName, pageCacheInSec };
}

let vendorCache: Record<string, any> = {};
let vendorCacheTimer: Record<string, number> = {};

export async function getVendorsWithCache(env: string, pageCacheInSec: number) {
  // If data exists in cache and it's not expired, return it
  if (vendorCache[env] && Date.now() < vendorCacheTimer[env]) {
    return vendorCache[env];
  }

  // Otherwise, fetch data and store it in cache
  const data = await getVendors(env);
  vendorCache[env] = data;
  console.log('vendorCache refreshed for env', env, 'at', new Date());

  // Set the cache to expire in 30 minutes
  vendorCacheTimer[env] = Date.now() + pageCacheInSec * 1000;

  return data;
}

let headerCache: Record<string, any> = {};
let headerCacheTimer: Record<string, number> = {};


function getHeaderContent(env: string) {
  return fetch(`https://cms.nestingale.com/api/global-headers?fields[0]=${env || 'prod'}`)
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
}

export async function getHeaderDataWithCache(env: string, pageCacheInSec: number = 1800) {
  // If data exists in cache and it's not expired, return it
  if (headerCache[env] && Date.now() < headerCacheTimer[env]) {
    return headerCache[env];
  }

  // Otherwise, fetch data and store it in cache
  const data = await getHeaderContent(env);
  headerCache[env] = data.data[0].attributes[env];
  console.log('headerCache refreshed for env', env, 'at', new Date());

  // Set the cache to expire in 30 minutes
  headerCacheTimer[env] = Date.now() + pageCacheInSec * 1000;

  return headerCache[env];
}
