'use client';
import React from "react";
import cssStyles from './help_me.module.scss';

export default function HelpMe({onClickHandler}: any) {
    return (
        <div className={cssStyles.help_me} onClick={(e) => {
            onClickHandler();}}>
            <div className={cssStyles.question_symbol}>?</div>
            <div className={cssStyles.help_me_text} style={{ textAlign: "center" }}>Help me decide</div>
        </div>
    );
};
    