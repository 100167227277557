import '../styles/globals.css';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '../../src/createEmotionCache';
import { wrapper } from "../store/store"
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import { themeCreator } from "../components/themes/base";
import CssBaseline from '@mui/material/CssBaseline';
import Head from 'next/head';
import Layout from '../components/layout';
import BuilderLayout from '../components/builderLayout';
import { CircularProgress, StylesProvider } from '@material-ui/core';
import { useRouter } from 'next/router';
import { Amplify } from 'aws-amplify';
import awsConfig from '@/utils/aws-config';
import { API_BASE_URL, getCustIdFromLS, getUserInfo, CartInfo, getUserCartInfo } from '@/utils/useUserInfo';
import { getLoggedInEmailAddress, getLoggedInName, isHeaderNotActiveOnPage, gtmPageOnloadTracking } from '@/utils';
import NestError from '@/components/nesterror';
import NextNProgress from 'nextjs-progressbar';
import { ErrorBoundary } from "react-error-boundary";
import { GetOnboardinData } from '@/store/questionniareSlice';
import { useShopify } from "../hooks/useShopify";
import { CART_TOKEN, GCART_TOKEN } from "@/utils";



function localStorageValues(emailAddress: string) {
  // Server-side code has rendering issues, as html is not matched with client side rendering
  // Retrieve the user's name from local storage and return in 
  //useEffect in client side and pass these as props to header component so that Next.js (server vs client) rendering wont complain.
  const showCart = typeof window !== 'undefined';
  const userName = getLoggedInName();
  return {
    emailAddress,
    showCart,
    userName
  }
}

//@ts-ignore
(typeof window !== 'undefined') && (window.LOG_LEVEL = 'INFO');


export function configureAmplify(requestObj: any) {
  Amplify.Logger.LOG_LEVEL = 'INFO';
  Amplify.configure(awsConfig(requestObj));
}
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  hostName?: string;
  env?: string;
}

function App(props: MyAppProps) {

//   console.log("---------- in APP ------------path = ", props.router.asPath);

  (typeof window !== 'undefined') && configureAmplify({ protocol: window.location.protocol.split(":")[0], domain: window.location.host })

  const router = useRouter()

  const { resetRooms3DInfo } = GetOnboardinData();
  const { checkoutState, createCheckout } = useShopify();

  const [isLoaded, setLoaded] = useState(false);
  const [loginEl, setLoginEl] = useState(null);

  const env = process.env.NEXT_PUBLIC_APP_ENV;


    /*
     * reset any lingering cookies after order has been placed.
     * earlier the function clearCartCookieIfCartIdNotPresent attemped to do this
     * TODO we should find a better place for this code.
     */
    useEffect(
        function clearCartCookiesAfterOrderCompletion() {
           if (typeof window !== 'undefined') {
             const windowObj = window as any;
             let checkoutId = windowObj.getCookie(CART_TOKEN);
              console.log('--------_app.tsx -------cart token found. id: ', checkoutId);
              if (checkoutId !== null && checkoutId !== 'null') {
                 createCheckout({ checkoutId }).then((response:any) => {
                  console.log('--------_app.tsx --------checkout response: ', response);
                   if (response?.payload?.completedAt !== null) { //order already placed for this cookie
                     windowObj.setCookieWithoutSecure(CART_TOKEN,null,10);
                     windowObj.setCookieWithoutSecure(GCART_TOKEN,null,10);
                   }
                 });
              }
           }
        },
    [])

    //console.log("props", props);
  const { Component, emotionCache = clientSideEmotionCache, pageProps, hostName } = props;

  const isBuilderPath = router.pathname === '/[vendor]/builder';
  const emailAddress = getLoggedInEmailAddress();
  const [globalData, setGobalData] = useState({ userInfo: null } as any);
  const userInfoRef = useRef<{ [key: string]: string | boolean }>({ loadStatus: false });

  //TODO: avoid strigfication and parsing between components in server side
  const serverState = typeof pageProps.message == "string" ? JSON.parse(pageProps.message) : pageProps.message ? pageProps.message : {};
  const brandName = serverState?.brand || "nestingale";
  const popupView = serverState?.popupView || false;
  const vendors = serverState?.vendorsData && serverState?.vendorsData?.vendors;
  const brand = vendors?.filter((vendor: { vendorName: any; }) => vendor.vendorName === brandName)[0];
  const theme = themeCreator(brandName + "Theme");
  const canonicalUrl = serverState?.canonicalUrl || (API_BASE_URL + (router.asPath === "/" ? "" : router.asPath)).split("?")[0];
  const headerMenuJson = serverState?.headerMenuJson || [];

  useEffect(() => {
    if(pageProps.title) {
      document.title = pageProps.title;
      let brandName = brand?.vendorName || "Nestingale";
      brandName = brandName.charAt(0).toUpperCase() + brandName.slice(1);
      let dataLayerObj = {
        pageName: `${brandName}: ${pageProps.title}`,
        pageType: `${pageProps.title} Page`,
        brandName: brandName,
      }
      if (pageProps.dataLayer) {
        Object.assign(dataLayerObj, pageProps.dataLayer);
      }
      gtmPageOnloadTracking(dataLayerObj);
    }
  }, [pageProps?.title]);

  const localStorageObj = useMemo(() => {
    return localStorageValues(emailAddress)
  }, [emailAddress])

  useEffect(() => {
    resetRooms3DInfo();
  }, [router.asPath]);

  useEffect(() => {
    // Create a custom event listener
    const routeChangeStartListener = () => {
      resetRooms3DInfo();
    };
    
    // Add the event listener to the router
    router.events.on("routeChangeStart", routeChangeStartListener);
    window.addEventListener('popstate', routeChangeStartListener);
    return () => {
      // Remove the event listener from the router when the component is unmounted
      router.events.off("routeChangeStart", routeChangeStartListener);
      window.removeEventListener('popstate', routeChangeStartListener);
    };
  }, []);

  const [userInfo, setUserInfo] = useState({status: "INITITATED"} as any);
  
  useEffect( () => { 
    if (isHeaderNotActiveOnPage()) {
      return;
    }
    //@ts-ignore
    window.checkingForUserSession=true;
    getUserInfo("", true).then((userInfo) => {
      setUserInfo(userInfo ? {...userInfo, status: "DONE"} :  { status: "DONE" });
    });
  },[])

  useEffect(() => {
          console.log("--- APP : userInfo", JSON.stringify(userInfo));
          //@ts-ignore
          window.checkingForUserSession=false;
          if (userInfo.status === "DONE") {
            userInfoRef.current.loadStatus = true;
            setGobalData({ userInfo, lsv: localStorageObj });
            if (userInfo.email) {
              userInfoRef.current = { ...userInfoRef.current, ...userInfo, ...localStorageObj };              
            } else {
              userInfoRef.current = { ...userInfoRef.current, unauthorized: true };
            }
          }
  }, [userInfo]);

  globalData.loginCallback = (callbackObj : {type: string, status: string, userInfo:any}) => {
    let capType = callbackObj.type.charAt(0).toUpperCase() + callbackObj.type.slice(1);
    if (callbackObj.status === "LOGIN_SUCCESS") {
      console.log("loginCallback", callbackObj);
      if (userInfo === null || !userInfo.email) {
        getUserInfo("", true).then((userInfo) => {
          setUserInfo(userInfo ? {...userInfo, status: "DONE"} :  { status: "DONE" });
        });
      } else {
        setUserInfo({...userInfo, ...callbackObj.userInfo, status: "DONE"});
      }
      gtmPageOnloadTracking({event: "User_Web_Login_Successful_"+capType , status: callbackObj.status});
    } else {
      gtmPageOnloadTracking({event: "User_Web_Login_Failed_"+capType , status: callbackObj.status});
    }
  }

 //console.log("globalData", globalData);

  function Fallback({ error, resetErrorBoundary }:{error: Error,resetErrorBoundary:any}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    let customerId = '';
    try{
      customerId = getCustIdFromLS()
    }catch(e){}
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        {
        (customerId && (customerId!= 'null' && customerId!='undefined' ))?
        <p>customerId: {customerId}</p> : <></>}
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }
  
  const logError = (error: Error, info: { componentStack: string }) => {
    // Do something with the error, e.g. log to an external API
    console.error(error);
  };
  
  return (
    <ErrorBoundary
      FallbackComponent={Fallback} onError={logError}>
      <NextNProgress />
      <CacheProvider value={emotionCache}>
        <StylesProvider injectFirst>
          <Head>
            <title>{pageProps.title ? pageProps.title : "Home Design Inspiration, High Quality Furniture - Nestingale"}</title>
            {(pageProps.noIndex || env != 'prod') && <meta name="robots" content="noindex, nofollow" />}
            <meta name="description" content={pageProps.description} />
            <link rel="canonical" href={canonicalUrl} />
            <meta name="theme-color" content={theme.palette.primary.main} />
          </Head>
          <ThemeProvider theme={theme}>

            <CssBaseline />
            {
              pageProps.error 
              ? 
                <Layout loginEl={loginEl} setLoginEl={setLoginEl} env={serverState.env} brand={brand} popupView={popupView} path={router.asPath} globalData={globalData}
                  headerType={serverState.headerType ? serverState.headerType : 'full'} headerMenuJson={headerMenuJson || {}}>
                  <NestError {...pageProps} env={serverState.env} />
                </Layout>
              : <>
                {
                  router.isFallback ? <CircularProgress size="30" /> :

                    !isBuilderPath ? (
                      <Layout loginEl={loginEl} setLoginEl={setLoginEl} env={serverState.env} brand={brand} popupView={popupView} path={router.asPath} globalData={globalData}
                        headerType={serverState.headerType ? serverState.headerType : 'full'} headerMenuJson={headerMenuJson || {}} >
                        <Component env={serverState.env} loginEl={loginEl} setLoginEl={setLoginEl} {...pageProps} {...globalData} setGobalData={setGobalData} />
                      </Layout>) : (
                      <BuilderLayout brand={brand}>
                        <Component env={serverState.env}  loginEl={loginEl} setLoginEl={setLoginEl} {...pageProps} userInfo={userInfoRef.current} setGobalData={setGobalData} />
                      </BuilderLayout>)
                }
              </>
            }
          </ThemeProvider>
        </StylesProvider>
      </CacheProvider>
    </ErrorBoundary>
  );
}

export default wrapper.withRedux(App);

