import Head from 'next/head';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderComponent from './header';
import FooterComponent from './footer';


export default function Layout(props) {
  const { children, brand, popupView, headerType, globalData,  loginEl, setLoginEl, headerMenuJson } = props;
  let path = props.path.split("?")[0];
  const isPopupView = popupView || false;
  const noHeaderFooterList = [
    "login",
    "successconfirmation",
    'product3d'
  ];
  const shortHeaderList = [
    "stylequiz",
    "styleprofile",
    "roomquiz",
    "recommendations",
    "builder",
    "cart",
    "uploadInstructions"
  ]
  return (
    <>
      {!isPopupView &&
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>}
      {/* <div className="promo_banner">
        <span>Memorial Day 2024 Sale - Save Up to 30% Off Home Decor <a href="/deals">See All Deals</a></span>
      </div> */}
      <div className={`main-wrapper ${path === '/' ? 'full_width' : ''}`}>
        <div id="signup-a">
          {!isPopupView && path && !path.includes('threed') && !noHeaderFooterList.includes(path.split("/")[1])
            && (<div className='header_container'>
              <HeaderComponent  
                loginEl={loginEl} 
                setLoginEl={setLoginEl} 
                loginCheckLoading={props.loaded} 
                brand={brand} 
                globalData={globalData} 
                headerType={shortHeaderList.includes(path.split("/")[1]) ? 'short' : 'full'}
                headerMenuJson={headerMenuJson} />
            </div>)}
          <div className='container_wrapper'>
            <CssBaseline />
            <main>{children}</main>
          </div>

        </div>
      </div>
      {!isPopupView && path && !path.includes('threed') && !noHeaderFooterList.includes(path.split("/")[1]) && (<div className='footersection'>
        <div className='footer_container'><FooterComponent brand={brand} /></div>
      </div>)}
    </>
  )
}