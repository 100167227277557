import { getGoogleCredentialsFromLS } from "@/load_and_get_google_access_token";
import { useEffect, useState } from "react";
import { getFBKCredentialsFromLS } from "./load_and_get_facebook_access_token";
import { getDirectCognitoCredentialsFromLS } from "@/components/login";
import { getAppleCredentialsFromLS } from "./load_and_get_apple_access_token";
import { getEitherCustIdFromLs } from "./useUserInfo";

export const CART_TOKEN = "cartToken";
export const GCART_TOKEN = "gcartToken";
interface BrandDetails {
  name: string,
  logoUrl: string
}

export const Brands = {
  Nestingale : {name: "", logoUrl: null},
  Ammerica : {name: "aamerica", logoUrl: "https://d221a61rb87vel.cloudfront.net/catalog-assets/vendor/logos/aamerica-logo.svg"},
  Flexsteel : {name: "flexsteel", logoUrl:"https://d221a61rb87vel.cloudfront.net/catalog-assets/vendor/logos/flexsteel_logo.png"},
  Homestyles : {name: "homestyles", logoUrl:"https://d221a61rb87vel.cloudfront.net/catalog-assets/vendor/logos/homestyles-logo-B-200x80_200x80.jpg"},
  addison: {name: "addison",logoUrl:"https://d221a61rb87vel.cloudfront.net/catalog-assets/vendor/logos/Addison House.jpeg"},
  moes: {name: "moes",logoUrl:"https://d221a61rb87vel.cloudfront.net/catalog-assets/vendor/logos/MoesLogo.jpeg"},
  stevesilver: {name: "stevesilver",logoUrl:"https://d221a61rb87vel.cloudfront.net/catalog-assets/vendor/logos/SteveSilverLogo.jpg"}
} as any;

// To navigate to a different page programmatically
export function navigateWithHistory(router: any, path: string, spa: boolean = false) {
  if (spa) {
    router.push(path);
  } else {
    window.location.href = path
  }
};

export function navigateAndReplaceHistory(router:any, path: string) {
  router.push(path, undefined, { shallow: true, replace: true });
};
export const navigateWithOutPreviousHistory = (router: any, path: any) => {
  router.push(path, undefined, { shallow: true, replace: true });
};

export function getBaseName() {
  
    const pathname =  typeof window !== "undefined" ? new URL(window.location.href).pathname : '/';
    const brands = Object.keys(Brands);
    let baseBrand = Brands.Nestingale;
    for (const value of brands) {
      if(pathname.indexOf(`/${Brands[value].name.toLowerCase()}/`) >=0 ){
        baseBrand = Brands[value];
        break;
      }
    }
    console.log("------ Basebrand : "+JSON.stringify(baseBrand));
    return baseBrand;
  
}

//https://www.petermorlion.com/iterating-a-typescript-enum/
function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const DeviceDetector = () => {
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  return deviceType;
};

export const isIos = (): boolean => {
  return DeviceDetector() === "Mobile" ? /iPhone|iPad|iPod/i.test(navigator.userAgent) : false as boolean;
}

export const getCurrentSession = () => {

  let session = getDirectCognitoCredentialsFromLS();

  let loginType = "cognito";
  let isSessionValid = false;
  if (session) {
      isSessionValid = !isTokenExpired(session);
  } else {
      session = getGoogleCredentialsFromLS();
      if (session) {
          loginType = "google";
          isSessionValid = !isTokenExpired(session);
      } else {
          session = getFBKCredentialsFromLS();
          if (session) {
              loginType = "facebook";
              isSessionValid = !isTokenExpired(session);
          } else {
              session = getAppleCredentialsFromLS();
              if (session) {
                  loginType = "apple";
                  isSessionValid = !isTokenExpired(session);
              }
          }
      }
  }
  return { session, loginType, isSessionValid };
}

const isTokenExpired = (loginInfo: any) => {
  let expiration = new Date(loginInfo.expiration);
  let currentTime = new Date();
  return !loginInfo.authenticated || currentTime > expiration;
}   


export function getLoggedInName(){
  const googleCredentials = getGoogleCredentialsFromLS() || {};
  const facebookCredentials = getFBKCredentialsFromLS() || {};
  const cognitoCredentials = getDirectCognitoCredentialsFromLS() || {};
  const appleCredentials = getAppleCredentialsFromLS() || {};
  const name = googleCredentials.name || facebookCredentials.name || cognitoCredentials.name || appleCredentials.name|| "User";
  return name;
}

export function getLoggedInEmailAddress(){
  const googleCredentials = getGoogleCredentialsFromLS() || {};
  const facebookCredentials = getFBKCredentialsFromLS() || {};
  const cognitoCredentials = getDirectCognitoCredentialsFromLS() || {};
  const appleCredentials = getAppleCredentialsFromLS() || {};
  const emailAddress = googleCredentials.email || facebookCredentials.email || cognitoCredentials.email  || appleCredentials.email || null;
  return emailAddress;
}

export function isCartPage(isFromHeader:boolean=false){
  const isWindowPresent = typeof window !== 'undefined';

  if(!isWindowPresent){
    //console.log("executed in server side.......isFromHeader=",isFromHeader)
    return true;
  }

  if(isWindowPresent){
    const pageUrl = window.location.href;
    if((pageUrl.indexOf("cart") >= 0)){
      return true;
    }
  }

  return false;
}


export function isHeaderNotActiveOnPage(isFromHeader:boolean=false){
  const isWindowPresent = typeof window !== 'undefined';

  if(!isWindowPresent){
    //console.log("executed in server side.......isFromHeader=",isFromHeader)
    return true;
  }

  // if(isWindowPresent){
  //   const pageUrl = window.location.href;
  //   if((pageUrl.indexOf("welcome") >= 0) || 
  //   (pageUrl.indexOf("profile") >= 0) || 
  //   (pageUrl.indexOf("successconfirmation") >= 0) ||
  //   (pageUrl.indexOf("projectgoal") >= 0) ||
  //   (pageUrl.indexOf("styleprofile") >= 0)){
  //     return true;
  //   }
  // }

  return false;
}

function componentToHex(c:any) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r:any, g:any, b:any) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function deepEqual(objA:any, objB:any) {
	if (objA === objB) return true;
  
	if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) return false;
  
	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);
  
	if (keysA.length !== keysB.length) return false;
  
	for (let key of keysA) {
	  if (!keysB.includes(key) || !deepEqual(objA[key], objB[key])) return false;
	}
  
	return true;
  }

 export function checkIfImageExists(url:string, callback:any) {
  const img = new Image();
  img.src = url;
  
  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };
    
    img.onerror = () => {
      callback(false);
    };
  }
}

export function dataURItoBlob(dataURI:string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
}

export function htmlEncode(str: string) {
  var buf = [];

  for (var i = str.length - 1; i >= 0; i--) {
    buf.unshift(['&#', str.charCodeAt(i), ';'].join(''));
  }

  return buf.join('');
}

export function HtmlEncode(s:string)
{

  var HTMLCharMap = {

    '&': '&amp;',

    "'": '&#39;',

    '"': '&quot;',

    '<': '&lt;',

    '>': '&gt;',

    '\\': '&#x5c;',

    '`': '&#x60;',

    ':': '&#58;',

  };

  function encodeHTMLmapper(ch: string) {
    const HTMLCharMap: { [key: string]: string } = {
      '&': '&amp;',
      "'": '&#39;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;',
      '\\': '&#x5c;',
      '`': '&#x60;',
      ':': '&#58;',
    };

    return HTMLCharMap[ch];
  }

  return s.replace(/[&"'<>\\`:]/g, encodeHTMLmapper);

}

export function sanitizeNextParam(nextParam: string, encoded: boolean = false): string {
  
  if (!nextParam) {
    return '/';
  }
  
  if (encoded) {
    nextParam = decodeURIComponent(nextParam);
  }
  
  let originalNextParam = nextParam;

  nextParam = nextParam.replace(/[<>*]/g, '');

  // If nextParam starts with http or https, replace it with the path
  if (nextParam.startsWith('http://') || nextParam.startsWith('https://')) {
    let url = new URL(nextParam);
    nextParam = url.pathname + url.search + url.hash;
  }

  // Ensure nextParam starts with a '/'
  if (!nextParam.startsWith('/')) {
    nextParam = '/' + nextParam;
  }

  if (nextParam !== originalNextParam) {
    console.log('Sanitized nextParam from  ', originalNextParam, '  to  ', nextParam);
  } 

  if (encoded) {
    nextParam = encodeURIComponent(nextParam);
  }

  return nextParam;
}

declare global {
  interface Window {
    nestDataLayer: any[];
    pageViewDataLayerObj: any;
    userId: string;
  }
}

export function gtmPageOnloadTracking(dataLayerObj: any = {}) {
  window.nestDataLayer = window.nestDataLayer || [];
  dataLayerObj = { 
    'event': 'Pageview',
    'userId': getEitherCustIdFromLs(), 
    'pagePath': window.location.href, 
    'pageTitle': document.title,
    ...dataLayerObj,
  };
  if (window.localStorage.getItem('trafficType') === 'internal') {
    dataLayerObj['trafficType'] = 'internal';
  }
  window.pageViewDataLayerObj = {...dataLayerObj};
  window.nestDataLayer.push(dataLayerObj); 
  console.log('nestDataLayer: Pageview event push to GTM', window.nestDataLayer);
}

export function gtmClickTracking(dataLayerObj: any = {}) {
  window.nestDataLayer = window.nestDataLayer || [];
  dataLayerObj = { 
    'event': 'ClickView',
    'userId': getEitherCustIdFromLs(), 
    'pagePath': window.location.href, 
    'pageTitle': document.title,
    ...dataLayerObj,
  };
  if (window.localStorage.getItem('trafficType') === 'internal') {
    dataLayerObj['trafficType'] = 'internal';
  }

  window.nestDataLayer.push(dataLayerObj); 
  console.log('nestDataLayer: Pageview event push to GTM', window.nestDataLayer);
}

export function trackItem(trackObj: {}) {
  //@ts-ignore
  window?.nestDataLayer?.push({ 
      "pageType": window.pageViewDataLayerObj.pageType,
      "pageName": window.pageViewDataLayerObj.pageName,
      "pagePath": window.pageViewDataLayerObj.pagePath,
      ...trackObj
  });   
  console.log("Tracked item:", trackObj); 
}